import ApiService from "./api/ApiService";

import {API_ENDPOINTS} from "config/API_CONSTANTS";
import GamePackService from "./GamePackService";
import store from "redux/store";
import ApiDataParserService from "./api/ApiDataParserService";
import {batch} from "react-redux";
import SessionReduxService from "../redux/SessionReduxService";
import GameReduxService from "../redux/GameReduxService";
import CardsReduxService from "../redux/CardsReduxService";
import LoggerService from "./LoggerService";
import {setHasGamePackLoadError, setLang, setTeam, setToken, setUser} from "../../redux/slices/sessionSlice";
import AccountingService from "./AccountingService";
import {GAME_STEPS, GAMES} from "../../config/CONSTANTS";
import {setCanHireCards, setCurrentStep, setIsDemonstration} from "../../redux/slices/gameSlice";
import {
    addService,
    addSkill,
    setQuestionForStep,
    setSignedAccounting,
    updateFounder,
    updateFounderIcon,
    updateQuestion,
    updateStartup,
    updateValueProposition
} from "../../redux/slices/teamsSlice";
import GameControlService from "../../pages/gameMaster/ControlPanel/components/GameControl/services/GameControlService";
import Utils from "../../utils/Utils";
import DEMO_SESSION_CONFIG from "../../config/DEMO_SESSION_CONFIG";
import CardUtil from "../../utils/CardUtil";
import {
    setDemoSessionId,
    setDisableChallengesPagination,
    setForcePulsatePagination,
    updateDemoState,
    updateVideoPlayerState
} from "../../redux/slices/demoSlice";
import SkillCardModel from "../../models/SkillCardModel";
import AudioService from "./AudioService";
import {SOUND_EFFECTS} from "../../config/AUDIO_FILES";
import {i18nService} from "../../i18n";

const STORAGE_KEY = 'sm_demo_info';

function playVideo(videoNumber, stateAttribute) {
    const game = store.getState().game.gameInfo.game;
    const lang = store.getState().session.lang;

    store.dispatch(setDisableChallengesPagination(true));

    store.dispatch(updateVideoPlayerState({
        enable: true,
        play: true,
        // add "&rel=0" to NOT SHOW recommended videos after ending it
        video: DEMO_SESSION_CONFIG.VIDEOS[lang][game][videoNumber] + '&rel=0',
        onEnd: () => {
            store.dispatch(updateDemoState({[stateAttribute]: true}));
            store.dispatch(setDisableChallengesPagination(false));
        }
    }));
}

function getWebhookDefaultParams() {
    const sessionId = store.getState().demo.demoSessionId;
    const game = store.getState().game.gameInfo.game;
    const language = i18nService.getLanguage();

    let device = '';

    try {
        device += 'ontouchstart' in window || navigator?.maxTouchPoints > 0
            ? '[MOBILE]' : '[DESKTOP]';

        if (navigator?.userAgentData) {
            const userAgentData = structuredClone(navigator.userAgentData);
            delete userAgentData.brands;

            device += '| User agent data: ' + JSON.stringify(userAgentData)
        }
    } catch (e) {
        console.error(e);
    }

    return {sessionId, game, language, device};
}

function notifyGameAdvanced(stepDescription) {
    try {
        const webhookEndpoint = DEMO_SESSION_CONFIG.NOTIFY_ADVANCED_WEBHOOK;
        const defaultParams = getWebhookDefaultParams();

        if (!webhookEndpoint || !defaultParams.sessionId)
            return;


        // parse from format '2023-11-23T20:06:02.966Z' to '2023-11-23 20:06:03 UTC'
        const timestamp = (new Date).toISOString()
            .replace('T', ' ')
            .slice(0, -5) + ' UTC';


        const params = {
            step: stepDescription,
            timestamp,
            ...defaultParams
        };

        fetch(webhookEndpoint, {
            method: 'POST',
            body: JSON.stringify(params)
        }).catch(err => {
            console.error(err);
        });
    } catch (e) {
        console.error(e);
    }
}

const DemoSessionService = {
    getRejoinData() {
        return localStorage.getItem(STORAGE_KEY) || null;
    },

    async join({game, language, sessionId}) {
        if (store.getState().session.hasLoadedGameData === true)
            return true;

        localStorage.setItem(STORAGE_KEY, window.location.href);

        try {
            store.dispatch(setLang(language));

            if (sessionId)
                store.dispatch(setDemoSessionId(sessionId));

            const apiResponse = await ApiService.post(
                API_ENDPOINTS.GAME_SESSION.FETCH_DEMO_DATA,
                {game, language}
            );

            if (apiResponse.status !== true)
                return apiResponse;

            const apiData = apiResponse.data;


            // fetch game pack
            const gamePack = await fetch(apiData.gamePack.url);

            if (gamePack.status !== 200) {
                store.dispatch(setHasGamePackLoadError(true));
                return false;
            }

            // update game pack first because it is used by the ApiDataParserService.parseGameData() function
            const gameConfig = await GamePackService.parse(await gamePack.json());


            // set if game is SMGX or ESG
            AccountingService.setAccountingData(apiData?.gameSession?.game || GAMES.SMGX);


            batch(() => {
                    store.dispatch(setUser({
                        id: 'demo-player',
                        name: 'Demo Player',
                        nickname: 'Demo Player',
                        localPlayers: []
                    }));

                    store.dispatch(setToken('demo-session'));
                    store.dispatch(setIsDemonstration(true));
                    store.dispatch(setTeam(DEMO_SESSION_CONFIG.TEAM));
                    SessionReduxService.updateShowBoardForTeam(DEMO_SESSION_CONFIG.TEAM);

                    CardsReduxService.updateAllCards(gameConfig.cards);
                    GameReduxService.updateGameCustomization(gameConfig.game ?? {});
                    GameReduxService.updateConceptionThemes(gameConfig.conceptionThemes ?? {});

                    GameReduxService.updateAllData({
                        gameInfo: {
                            gamePack: {
                                id: apiData.gamePack?.name || 'default',
                                packVersion: gameConfig.packVersion,
                                contentVersion: gameConfig.contentVersion
                            }
                        }
                    });
                }
            );


            // parse data
            const parsedResponse = ApiDataParserService.parseGameData(apiData.gameSession);

            // update all data
            batch(() => {
                GameReduxService.updateAllData(parsedResponse);
                store.dispatch(setCurrentStep(DEMO_SESSION_CONFIG.INITIAL_STEP));
                SessionReduxService.updateHasLoadedData(true);
                SessionReduxService.updateHasLoadedTeamData(1);
                store.dispatch(setForcePulsatePagination(true));
            });

            return true;
        } catch
            (error) {
            console.error(error);
            LoggerService.error(error, {action: 'join - DemoSessionService'});
            return false;
        }
    },

    leave() {
        localStorage.removeItem(STORAGE_KEY);
    },

    start() {
        store.dispatch(setCurrentStep(GAME_STEPS.CONCEPTION));
        notifyGameAdvanced('[Etapa] Concepção');
    },

    setStartup(startup) {
        store.dispatch(updateStartup({team: 1, startup}));
    },

    setFounder(number, founder, iconNumber) {
        store.dispatch(updateFounderIcon({team: 1, number, icon: SkillCardModel.getFullPathForIcon(iconNumber)}));
        store.dispatch(updateFounder({team: 1, number, founder}));
    },

    setValueProposition(number, vp) {
        store.dispatch(updateValueProposition({team: 1, number, valueProposition: vp}));
    },

    skipConception() {
        DemoSessionService.setStartup(Utils.randomFromArray(store.getState().cards.startups));
        DemoSessionService.setFounder(0, Utils.randomFromArray(store.getState().cards.skills), SkillCardModel.randomIconNumber());
        DemoSessionService.setFounder(1, Utils.randomFromArray(store.getState().cards.skills), SkillCardModel.randomIconNumber());
        DemoSessionService.setValueProposition(0, Utils.randomFromArray(store.getState().cards.valuePropositions));
        DemoSessionService.setValueProposition(1, Utils.randomFromArray(store.getState().cards.valuePropositions));

        notifyGameAdvanced('[Ação] Concepção pulada');

        DemoSessionService.advanceToQuestionStep(true);
    },

    advanceToQuestionStep(skippedConception = false) {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;

        const questions = GameControlService.getQuestionsForTeams(step);
        const questionIndex = Utils.randomFromArray(questions).question;
        const questionCard = CardUtil.getCardById(store.getState().cards.questions, questionIndex);

        const question = {
            card: questionCard,
            showRes: false,
            answer: [],
            isCorrect: false,
        }

        batch(() => {
            store.dispatch(setCurrentStep(step));
            store.dispatch(setQuestionForStep({team, step, question}));
            store.dispatch(setForcePulsatePagination(true));
        });

        AccountingService.calculateForTeam(team);
        notifyGameAdvanced('[Etapa] Pergunta: ' + questionCard.question);
    },

    setQuestionAnswer(answer) {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;

        store.dispatch(updateQuestion({team, step, data: {answer}}));
        store.dispatch(updateDemoState({revealQuestion: true}));
    },

    isQuestionCorrect() {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;
        const question = store.getState().teams[team].questions[step];

        return CardUtil.isAssignedQuestionAnswerCorrect(question)
    },

    getSelectedQuestionOptions() {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;

        return store.getState().teams[team].questions[step].answer;
    },

    setHasRevealedQuestion() {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;
        const isCorrect = DemoSessionService.isQuestionCorrect();

        batch(() => {
            store.dispatch(updateDemoState({revealedQuestion: true}));
            store.dispatch(updateQuestion({team, step, data: {isCorrect, showResult: true, showOutcome: true}}));
            store.dispatch(updateQuestion({team, step, data: {showOutcome: true}}));
            store.dispatch(setForcePulsatePagination(true));
            store.dispatch(setCanHireCards(true));

            store.dispatch(setDisableChallengesPagination(true));
        });

        AccountingService.calculateForTeam(team);
        notifyGameAdvanced('[Etapa] Resposta da pergunta revelada');
    },

    revealUnpredictability() {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;

        store.dispatch(updateDemoState({hasRevealedUnpredictability: true}));
        store.dispatch(updateQuestion({team, step, data: {}}));

        AccountingService.calculateForTeam(team);
        notifyGameAdvanced('[Etapa] Imprevisibilidade revelada');
    },

    signAccounting(signature) {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;

        store.dispatch(setSignedAccounting({team, step, data: signature}))
    },

    hireSkill({skill, iconNumber, gender}) {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;

        store.dispatch(addSkill({
            team,
            step,
            data: {
                id: Utils.uuid(),
                step: step,
                hiredAt: (new Date()).getTime(),
                iconNumber,
                card: skill,
                icon: SkillCardModel.getFullPathForIcon(iconNumber),
                gender
            }
        }));

        AudioService.play(SOUND_EFFECTS.CARDS.HIRED);
        AccountingService.calculateForTeam(team);
    },

    hireService({service}) {
        const team = DEMO_SESSION_CONFIG.TEAM;
        const step = DEMO_SESSION_CONFIG.QUESTION_STEP;

        store.dispatch(addService({
            team,
            step,
            data: {
                id: Utils.uuid(),
                step: step,
                hiredAt: (new Date()).getTime(),
                card: service,
            }
        }));

        AudioService.play(SOUND_EFFECTS.CARDS.HIRED);
        AccountingService.calculateForTeam(team);
    },

    playWelcomeVideo() {
        playVideo(0, 'hasPlayedIntroVideo');
    },

    playJourneyVideo() {
        playVideo(1, 'hasPlayedJourneyVideo');
    },

    playQuestionVideo() {
        playVideo(2, 'hasPlayedQuestionVideo');
    },

    playUnpredictabilityVideo() {
        playVideo(3, 'hasPlayedUnpredictabilityVideo');
    },

    stopVideo() {
        store.dispatch(updateVideoPlayerState({
            enable: false,
            play: false
        }));

        DemoSessionService.toggleChallengesPagination(true);
    },

    toggleChallengesPagination(enabled) {
        store.dispatch(setDisableChallengesPagination(!enabled));
    },

    setVisitedAccounting() {
        if (!store.getState().demo.state.hasVisitedAccounting)
            notifyGameAdvanced('[Ação] Contabilidade visitada');

        store.dispatch(updateDemoState({hasVisitedAccounting: true}));
    },

    setVisitedMarket() {
        if (!store.getState().demo.state.hasVisitedMarket)
            notifyGameAdvanced('[Ação] Mercado visitado');

        store.dispatch(updateDemoState({hasVisitedMarket: true}));
    },

    pulseAccounting(pulse) {
        store.dispatch(updateDemoState({pulseAccounting: pulse}));
    },

    pulseMarket(pulse) {
        store.dispatch(updateDemoState({pulseMarket: pulse}));
    },

    notifyDemoSessionEnded() {
        try {
            store.dispatch(updateDemoState({hasNotifiedSessionEnded: true}));

            const defaultParams = getWebhookDefaultParams();
            const webhookEndpoint = DEMO_SESSION_CONFIG.WEBHOOK_ENDPOINT;

            if (!webhookEndpoint || !defaultParams.sessionId)
                return;


            // parse from format '2023-11-23T20:06:02.966Z' to '2023-11-23 20:06:03 UTC'
            const finishedAt = (new Date).toISOString()
                .replace('T', ' ')
                .slice(0, -5) + ' UTC';


            const params = {
                finished_at: finishedAt,
                ...defaultParams
            };

            fetch(webhookEndpoint, {
                method: 'POST',
                body: JSON.stringify(params)
            }).catch(err => {
                console.error(err);
            });
        } catch (e) {
            console.error(e);
        }
    },
};

export default DemoSessionService;