import Utils from "utils/Utils";
import ApiDataUtil from "utils/ApiDataUtil";

import StartupCardModel from "models/StartupCardModel";
import PitchCardModel from "models/PitchCardModel";
import QuestionCardModel from "models/QuestionCardModel";
import ServiceCardModel from "models/ServiceCardModel";
import UnpredictabilityCardModel from "models/UnpredictabilityCardModel";
import ValuePropositionCardModel from "../../models/ValuePropositionCardModel";
import store from "../../redux/store";
import {SUPPORTED_LANGUAGES} from "../../i18n";
import CardUtil from "../../utils/CardUtil";
import SkillCardModel from "../../models/SkillCardModel";
import {ACCELERATION_BONUSES} from "../../config/CONSTANTS";

let _lang = null;

function touchLang() {
    _lang = store.getState().session.lang;
}

function translateAttribute(obj, key) {
    return _lang !== SUPPORTED_LANGUAGES.ENGLISH && obj[key + '_' + _lang]
        ? obj[key + '_' + _lang]
        : obj[key];
}

function translateQuestionOption(obj, index, key) {
    return _lang !== SUPPORTED_LANGUAGES.ENGLISH && obj['answers'][index][key + '_' + _lang]
        ? obj['answers'][index][key + '_' + _lang]
        : obj['answers'][index][key];
}

function parseGameCards(cards) {
    const result = {};

    const parseBonus = (card) => {
        if (!card['related_bonus'])
            return [];

        const bonuses = [];


        if (card['related_bonus'].includes('i'))
            bonuses.push(ACCELERATION_BONUSES.IMPLEMENTATION);

        if (card['related_bonus'].includes('r'))
            bonuses.push(ACCELERATION_BONUSES.REPORTING);

        if (card['related_bonus'].includes('me'))
            bonuses.push(ACCELERATION_BONUSES.MONITORING);

        return bonuses;
    }


    result.pitches = cards.pitches.reduce((arr, c) => {
        if (typeof c === 'string') return arr;

        c.investment_per_ranking = c.investment_per_ranking || {};

        const investmentPerRanking = {
            p1: Utils.isset(c.investment_per_ranking.p1) ? c.investment_per_ranking.p1 : 0,
            p2: Utils.isset(c.investment_per_ranking.p2) ? c.investment_per_ranking.p2 : 0,
            p3: Utils.isset(c.investment_per_ranking.p3) ? c.investment_per_ranking.p3 : 0,
            p4: Utils.isset(c.investment_per_ranking.p4) ? c.investment_per_ranking.p4 : 0,
        };

        arr.push(new PitchCardModel({
            id: c.id,
            phase: c.phase,
            header: translateAttribute(c, 'header'),
            content: translateAttribute(c, 'content'),
            monthsForward: c.months_forward,
            monthCost: c.month_cost,
            revenue: c.revenue,
            investmentPerRanking: investmentPerRanking,
        }));

        return arr;
    }, []);

    result.questions = cards.questions.reduce((arr, c) => {
        if (typeof c === 'string') return arr;

        const answers = c.answers.map((answer, index) => {
            return {
                order: answer.order,
                description: translateQuestionOption(c, index, 'description'),
                explanation: translateQuestionOption(c, index, 'explanation'),
                isCorrect: answer.is_correct === true
            }
        }).sort((a, b) => a.order - b.order);


        const step = ApiDataUtil.parseQuestionStep(c.step);

        arr.push(new QuestionCardModel({
            id: c.id,
            phase: c.phase,
            step: step,
            concept: translateAttribute(c, 'concept'),
            question: translateAttribute(c, 'question'),
            order: c.order,
            answers: answers,
            relatedStartup: c.related_startup ?? null
        }));

        return arr;
    }, []);

    result.services = cards.services.reduce((arr, c) => {
        if (typeof c === 'string') return arr;

        let requirements = null;
        if (Utils.isset(c.requirements)) {
            requirements = c.requirements.map(req => {
                const type = ApiDataUtil.parseRequirementType(req.type);

                if (type === false) return null;

                return {
                    type: type,
                    amount: req.amount,
                    area: Utils.isset(req.area) ? ApiDataUtil.parseArea(req.area) : null,
                    card: Utils.isset(req.card) ? req.card : null,
                };
            }).filter(r => r !== null);
        }

        arr.push(new ServiceCardModel({
            id: c.id,
            type: ApiDataUtil.parseServiceType(c.type),
            title: translateAttribute(c, 'title'),
            description: translateAttribute(c, 'description'),
            area: ApiDataUtil.parseArea(c.area),
            price: c.price,
            accelerationPhase2: c.acceleration_phase_2,
            accelerationPhase3: c.acceleration_phase_3,
            requirements: requirements,
            // relatedValuePropositions: c.related_value_propositions ?? [],
            relatedBonus: parseBonus(c),
        }));

        return arr;
    }, [])

    result.skills = cards.skills.reduce((arr, c) => {
        if (typeof c === 'string') return arr;

        arr.push(new SkillCardModel({
            id: c.id,
            title: translateAttribute(c, 'title'),
            description: translateAttribute(c, 'description'),
            area: ApiDataUtil.parseArea(c.area),
            price: c.price,
            accelerationPhase2: c.acceleration_phase_2,
            accelerationPhase3: c.acceleration_phase_3,
            relatedBonus: parseBonus(c),
        }));

        return arr;
    }, []);

    result.startups = cards.startups.reduce((arr, c) => {
        if (typeof c === 'string') return arr;

        arr.push(new StartupCardModel({
            id: c.id,
            title: translateAttribute(c, 'title'),
            sector: translateAttribute(c, 'sector'),
            problem: translateAttribute(c, 'problem'),
            solution: translateAttribute(c, 'solution'),
            businessModel: translateAttribute(c, 'business_model'),
            defaultFounders: c.default_founders || [],
        }));

        return arr;
    }, []);

    result.unpredictabilities = cards.unpredictabilities.reduce((arr, c) => {
        if (typeof c === 'string') return arr;

        arr.push(new UnpredictabilityCardModel({
            id: c.id,
            event: translateAttribute(c, 'event'),
            relatedCard: c.related_card || null,
            positiveConsequenceDescription: translateAttribute(c, 'positive_consequence_description'),
            negativeConsequenceDescription: translateAttribute(c, 'negative_consequence_description'),
            neutralConsequenceDescription: translateAttribute(c, 'neutral_consequence_description'),
            positiveAccelerationImpact: c.positive_acceleration_impact,
            negativeAccelerationImpact: c.negative_acceleration_impact,
            phases: c.phases
        }));

        return arr;
    }, []);

    result.valuePropositions = cards.value_propositions.reduce((arr, vp) => {
        if (typeof vp === 'string') return arr;

        arr.push(new ValuePropositionCardModel({
            id: vp.id,
            title: translateAttribute(vp, 'title'),
            description: translateAttribute(vp, 'description')
        }));

        return arr;
    }, []);

    return result;
}

function parseGamePackConceptionThemes(conceptionThemes, cards) {
    touchLang();

    const res = [];

    for (let theme of conceptionThemes) {
        let error = false;
        let relatedCards = [];

        for (let relatedCardId of theme.related_cards) {
            const card = CardUtil.getCardById(cards.startups, relatedCardId);

            if (!card) {
                error = true;
                break;
            }

            relatedCards.push(card);
        }

        if (!error) {
            res.push({
                title: translateAttribute(theme, 'title'),
                description: translateAttribute(theme, 'description'),
                relatedCards
            });
        }
    }

    return res;
}

const GamePackService = {
    async parse(gamePack) {
        touchLang();

        let cards = parseGameCards(gamePack.cards);
        cards = CardUtil.connectCardsByIds(cards);

        return {
            packVersion: gamePack?.pack_version || 'v1.0.0',
            contentVersion: gamePack?.content_version || 'v1.0.0',
            cards,
            game: gamePack?.game ?? {},
            conceptionThemes: gamePack.conception_themes
                ? parseGamePackConceptionThemes(gamePack.conception_themes, cards)
                : {}
        };
    },

    parseGamePackConceptionThemes(conceptionThemes) {
        touchLang();

        const cards = store.getState().cards.startups;
        const res = [];

        for (let theme of conceptionThemes) {
            let error = false;
            let relatedCards = [];

            for (let relatedCardId of theme.related_cards) {
                const card = CardUtil.getCardById(cards, relatedCardId);

                if (!card) {
                    error = true;
                    break;
                }

                relatedCards.push(card);
            }

            if (!error) {
                res.push({
                    title: translateAttribute(theme, 'title'),
                    description: translateAttribute(theme, 'description'),
                    relatedCards
                });
            }
        }

        return res;
    },
};

export default GamePackService;