import React from 'react';
import {Portal} from "react-portal";
import "./FullscreenPortal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function FullscreenPortal({children, onClose, wrapperClass = 'bg-dark', id = null}) {
    return (
        <Portal>
            <div id={id} className={`fullscreen-portal p-2 py-md-3 px-md-4 ${wrapperClass}`}>

                <div className="w-100 text-right">
                    <button type="button" className="btn btn-link text-white btn-close" onClick={onClose}>
                        <FontAwesomeIcon icon={['fas', 'times']} className={'fa-2x'}/>
                    </button>
                </div>

                <div className="position-relative animate__animated animate__faster animate__slideInDown">
                    {children}
                </div>
            </div>
        </Portal>
    );
}