import React, {useMemo, useState} from "react";

import "./ConceptionTheme.scss";
import GameCardLayout from "components/cards/layouts/GameCardLayout";
import SimpleBar from "simplebar-react";

import icon from "assets/img/board/ic_startup_dark.svg";
import {useTranslation} from "react-i18next";

const ConceptionTheme = ({theme, onSelect}) => {
    const {t} = useTranslation('pages/tournament/tournament_conception_modal');

    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true);
    const handleHideConfirm = () => setShowConfirm(false);
    const handleClick = () => onSelect(theme);


    const overlayClass = useMemo(() => showConfirm ? 'show' : '', [showConfirm]);

    return (<GameCardLayout>
        <div className={"box border-info bg-white text-left d-flex flex-column selectable conception-theme"}>

            <div className="box-full-head bg-info d-flex align-items-center">
                <img src={icon} className="skill-face position-absolute" alt="Founder avatar"/>
                <div className="w-100">{theme.title}</div>
            </div>

            <div className="startup-card-content-container flex-fill position-relative"
                 onClick={handleShowConfirm} onMouseLeave={handleHideConfirm}>
                <SimpleBar className="content-scrollable">
                    <p>{theme.description}</p>
                </SimpleBar>

                <div
                    className={"confirm-overlay h-100 position-absolute top left right  d-flex justify-content-center flex-column align-items-center text-center " + overlayClass}>

                    <button type="submit" className="btn btn-info text-white" onClick={handleClick}>
                        { t('buttons.select') }
                    </button>
                </div>
            </div>
        </div>
    </GameCardLayout>);
}

export default ConceptionTheme;