export const APIS = {
    SMGX_API: 'smgxApi'
};

export const API_ENDPOINTS = {
    TOURNAMENTS: {
        REQUEST_ACCESS_CODE: '/api/tournaments/request-access-code',
        VALIDATE_ACCESS_CODE: '/api/tournaments/validate-access-code',
        INITIALIZE_SESSION: '/api/tournaments/initialize-session',
        FINISH_SESSION: '/api/tournaments/finish-session',
    },
    GAME_SESSION: {
        CHECK_GAME_SESSION_STATUS: '/api/game-sessions/status',
        FETCH_GAME_DATA: '/api/game-sessions/session-data',
        FETCH_DEMO_DATA: '/api/game-sessions/demo-data',

        GET_AVAILABLE_PLAYER_CREDITS: '/api/game-sessions/available-credits',
        UPDATE_GAME_STEP: '/api/game-sessions/update-game-step',
        UPDATE_SETTINGS: '/api/game-sessions/update-settings',
        UPDATE_MAX_PLAYER_COUNT: '/api/game-sessions/update-max-player-count',
    },
    TEAMS: {
        FETCH_TEAM_DATA: '/api/teams/fetch-team-data',
        HIRE_SKILL: '/api/teams/hire-skill',
        HIRE_SERVICE: '/api/teams/hire-service',
        HIRE_REFUND: '/api/teams/refund-hiring',

        SIGN_ACCOUNTING: '/api/teams/sign-accounting',
        UPDATE_QUESTION_ANSWER: '/api/teams/update-question-answer',
        REVEAL_QUESTION: '/api/teams/reveal-question',
        SET_UNPREDICTABILITY: '/api/teams/set-unpredictability',
        REVEAL_UNPREDICTABILITY: '/api/teams/reveal-unpredictability',
        REVEAL_UNPREDICTABILITY_OUTCOME: '/api/teams/reveal-unpredictability-outcome',
        SET_QUESTIONS: '/api/teams/set-questions',
        SET_RANKING: '/api/teams/set-ranking',
        REVEAL_RANKING: '/api/teams/reveal-ranking',
        SEND_PITCH_BONUS: '/api/teams/send-pitch-bonus',
        UPDATE_PITCH_DATA: '/api/teams/update-pitch-data',
        UPDATE_PROFILE: '/api/teams/update-profile',
    },

    USERS: {
        JOIN_GAME_MASTER: '/api/users/join-game-master',
        JOIN_PLAYER: '/api/users/join-player',

        UPDATE: '/api/users/update-player',
        CHANGE_TEAM: '/api/users/change-team',
        UPDATE_NPS: '/api/users/update-nps',
        REMOVE_LOCAL_PLAYER: '/api/users/remove-local-player',
        GET_CERTIFICATE_AND_NPS_DATA: '/api/users/get-certificates-nps',
        KICK: '/api/users/kick',
        REMOVE_PLAYER: '/api/users/remove-player',
        UPDATE_TEAM: '/api/users/update-team',
    },

    UTILS: {
        VERIFY_CONNECTION: '/api/utils/verify-connection',
        TAKE_SESSION_SNAPSHOT: '/api/utils/take-snapshot',
    }
}