import React, {useState} from "react";
import FullscreenPortal from "components/FullscreenPortal";
import ConceptionModal from "../../common/Board/components/ConceptionModal/ConceptionModal";
import ConceptionTheme from "./components/ConceptionTheme";
import CustomTheme from "./components/CustomTheme";

import "./TournamentConceptionModal.scss";
import Utils from "utils/Utils";
import SkillCardModel from "models/SkillCardModel";
import ConfirmThemeModal from "./components/ConfirmThemeModal";
import TeamService from "services/player/TeamService";
import OverlayUtil from "utils/OverlayUtil";
import TOURNAMENT_CONFIG from "config/TOURNAMENT_CONFIG";
import ToastUtil from "utils/ToastUtil";
import CardUtil from "utils/CardUtil";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../hooks/useTranslationForGame";
import PreloadTranslationFiles from "./utils/PreloadTranslationFiles";

export default function TournamentConceptionModal({onClose}) {
    const {t} = useTranslation('pages/tournament/tournament_conception_modal');
    const tfg = useTranslationForGame(t);

    const themes = useSelector(state => state.game.conceptionThemes);

    const [useCustomTheme, setUseCustomTheme] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState(false);
    const [selectedThemeCards, setSelectedThemeCards] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);


    const handleSetRandomContext = async (context) => {
        const startup = Utils.randomFromArray(context.relatedCards);
        const founders = startup.defaultFounders;
        const valuePropositions = CardUtil.getMostUsedValuePropositionsForStartup(startup);

        const founder1Icon = SkillCardModel.randomIconNumber();
        const founder2Icon = SkillCardModel.randomIconNumber();

        setSelectedThemeCards({
            startup,
            founders,
            valuePropositions,
            foundersIcons: [founder1Icon, founder2Icon],
        });

        setSelectedTheme(context);
        setShowConfirmModal(true)
    }

    const handleConfirmPredefinedContext = async () => {
        OverlayUtil.show();

        const startup = selectedThemeCards.startup;

        const founder1 = selectedThemeCards.founders[0];
        const founder1Icon = selectedThemeCards.foundersIcons[0];

        const founder2 = selectedThemeCards.founders[1];
        const founder2Icon = selectedThemeCards.foundersIcons[1];

        const vp1 = selectedThemeCards.valuePropositions[0];
        const vp2 = selectedThemeCards.valuePropositions[1];


        const updateResult = await TeamService.updateProfile({
            team: TOURNAMENT_CONFIG.TEAM,
            startup: startup.id,
            theme: selectedTheme.title,
            founders: [
                {id: founder1.id, icon: founder1Icon, number: 0},
                {id: founder2.id, icon: founder2Icon, number: 1},
            ],
            valueProposition: [
                {id: vp1.id, number: 0},
                {id: vp2.id, number: 1}
            ]
        });

        OverlayUtil.hide();

        if (!updateResult) {
            ToastUtil.toastDanger('Error');
        } else {
            onClose();
        }
    }


    if (useCustomTheme || themes.length === 0)
        return <ConceptionModal onClose={onClose}/>


    return (<>
        <FullscreenPortal id='tournament-conception-modal' onClose={onClose} wrapperClass={"bg-dark text-center"}>
            <div className="animate__animated animate__fadeInDown">
                <h3 className="text-warning text-center mb-3 animate__animated">
                    {t('texts.title')}
                </h3>

                <p dangerouslySetInnerHTML={{__html: tfg('texts.subtitle')}}/>

                <p>{t('texts.subtitle_2')}</p>
            </div>

            <hr/>

            <div
                className="d-flex justify-content-center align-items-center animate__animated animate__fadeInUp animate__delay-1s animate__faster">
                <div className="themes-list d-flex justify-content-center align-items-stretch flex-wrap">
                    <CustomTheme onClick={() => setUseCustomTheme(true)}/>

                    {themes.map((context, i) => (
                        <ConceptionTheme key={i} theme={context} onSelect={() => handleSetRandomContext(context)}/>
                    ))}
                </div>
            </div>
        </FullscreenPortal>


        <PreloadTranslationFiles/>

        {showConfirmModal && (
            <ConfirmThemeModal theme={selectedTheme}
                               cards={selectedThemeCards}
                               onClose={() => setShowConfirmModal(false)}
                               onConfirm={handleConfirmPredefinedContext}/>
        )}
    </>);
}