import React from "react";

import "./CustomTheme.scss";
import {useTranslation} from "react-i18next";

import startupIcon from "assets/img/board/ic_startup_default.svg";
import founderIcon from "assets/img/board/skill_face.svg";
import valuePropositionIcon from "assets/img/board/ic_value_proposition_default.svg";
import useTranslationForGame from "hooks/useTranslationForGame";

export default function CustomTheme({onClick}) {
    const {t} = useTranslation('pages/tournament/tournament_conception_modal');
    const tfg = useTranslationForGame(t);

    return (
        <div className="conception-custom-theme" role={"button"} onClick={onClick}>
            <div
                className="d-flex justify-content-center align-items-center flex-column text-center h-100 pb-3 py-1 px-3">

                <div className="d-flex justify-content-center flex-wrap" style={{gap: 5}}>
                    <img src={startupIcon} className="icon" alt="Founder avatar"/>
                    <img src={founderIcon} className="icon" alt="Founder avatar"/>
                    <img src={valuePropositionIcon} className="icon" alt="Founder avatar"/>
                </div>


                <h5 className="mb-0 mt-3">
                    { tfg('buttons.create_custom_context') }
                </h5>
            </div>
        </div>
    );
}