import ApiService from "./api/ApiService";

import {API_ENDPOINTS} from "config/API_CONSTANTS";
import GamePackService from "./GamePackService";
import store from "redux/store";
import ApiDataParserService from "./api/ApiDataParserService";
import {batch} from "react-redux";
import SessionReduxService from "../redux/SessionReduxService";
import GameReduxService from "../redux/GameReduxService";
import CardsReduxService from "../redux/CardsReduxService";
import TeamReduxService from "../redux/TeamReduxService";
import LoggerService from "./LoggerService";
import {setHasGamePackLoadError} from "../../redux/slices/sessionSlice";
import AccountingService from "./AccountingService";
import {GAMES, SESSION_STATUS} from "../../config/CONSTANTS";

const GameSessionService = {
    checkGameSessionStatus(gameSessionId) {
        const params = {gameSessionId: gameSessionId};
        const path = API_ENDPOINTS.GAME_SESSION.CHECK_GAME_SESSION_STATUS;

        return ApiService.post(path, params, false).then(async response => {
            const responseData = response?.data;

            if (responseData?.gameSession) {
                const gameSessionData = ApiDataParserService.parseGameData(responseData.gameSession);

                if (gameSessionData.visualPack)
                    await GameReduxService.updateTheme(gameSessionData.visualPack);

                batch(async () => GameReduxService.updateAllData(gameSessionData));
            }


            let result = {
                status: response.status,
                sessionStatus: SESSION_STATUS.OK,
                gameSessionId: responseData?.gameSession?.id,
            };

            if (response.status !== true) {
                if (responseData.hasStarted === false)
                    result.sessionStatus = SESSION_STATUS.NOT_STARTED;
                else if (responseData.isClosed === true)
                    result.sessionStatus = SESSION_STATUS.CLOSED;
                else if (responseData.isPaused === true)
                    result.sessionStatus = SESSION_STATUS.PAUSED;
                else
                    result.sessionStatus = SESSION_STATUS.NOT_FOUND;
            }

            return result;
        }).catch(error => {
            // prevent the app from reporting 404 error when the session ID is invalid
            if (error?.response?.status !== 404) {
                console.error(error);
                LoggerService.error(error, {
                    action: 'check session status - GameSessionService',
                    params: gameSessionId
                });
            }

            return false;
        });
    },

    async fetchAllGameData() {
        if (store.getState().session.hasLoadedGameData === true) return true;

        try {
            const apiResponse = await ApiService.post(
                API_ENDPOINTS.GAME_SESSION.FETCH_GAME_DATA
            );

            if (apiResponse.status !== true)
                return apiResponse;

            const apiData = apiResponse.data;


            // fetch game pack
            const gamePack = await fetch(apiData.gamePack.url);

            if (gamePack.status !== 200) {
                store.dispatch(setHasGamePackLoadError(true));
                return false;
            }

            // update game pack first because it is used by the ApiDataParserService.parseGameData() function
            const gameConfig = await GamePackService.parse(await gamePack.json());


            // set if game is SMGX or ESG
            AccountingService.setAccountingData(apiData?.gameSession?.game || GAMES.SMGX);


            batch(() => {
                CardsReduxService.updateAllCards(gameConfig.cards);
                GameReduxService.updateGameCustomization(gameConfig.game ?? {});
                GameReduxService.updateConceptionThemes(gameConfig.conceptionThemes ?? {});

                GameReduxService.updateAllData({
                    gameInfo: {
                        gamePack: {
                            id: apiData.gamePack?.name || 'default',
                            packVersion: gameConfig.packVersion,
                            contentVersion: gameConfig.contentVersion
                        }
                    }
                });
            });


            // parse data
            const parsedResponse = ApiDataParserService.parseGameData(apiData.gameSession);
            const players = apiData.players.map(u => ApiDataParserService.parsePlayer(u));


            // update all data
            batch(() => {
                GameReduxService.updateAllData(parsedResponse);

                if (parsedResponse.team1) TeamReduxService.updateAllTeamData(1, parsedResponse.team1);
                if (parsedResponse.team2) TeamReduxService.updateAllTeamData(2, parsedResponse.team2);
                if (parsedResponse.team3) TeamReduxService.updateAllTeamData(3, parsedResponse.team3);
                if (parsedResponse.team4) TeamReduxService.updateAllTeamData(4, parsedResponse.team4);

                GameReduxService.addPlayer(players);
                SessionReduxService.updateHasLoadedData(true);
                SessionReduxService.updateHasLoadedTeamData(store.getState().session.team);
            });

            return true;
        } catch (error) {
            console.error(error);
            LoggerService.error(error, {action: 'fetchAllGameData - GameSessionService'});
            return false;
        }
    },
};

export default GameSessionService;