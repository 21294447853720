import Utils from "./Utils";
import {GAME_CARD_TYPE, GAME_PHASES, GAME_STEPS, REQUIREMENT_AREA} from "config/CONSTANTS";
import store from "redux/store";
import themes_vps_per_startup from "data/game_packs/themes_vps_per_startup.json";

let _maxCardAcceleration = null;

const CardUtil = {
    getMaxAcceleration(cardType) {
        if (!_maxCardAcceleration) {
            const skillsAccelerations = store.getState().cards.skills.reduce((accelerationsArray, card) => {
                accelerationsArray.push(...[card.accelerationPhase3, card.accelerationPhase2]);
                return accelerationsArray;
            }, []);

            const servicesAccelerations = store.getState().cards.services.reduce((accelerationsArray, card) => {
                accelerationsArray.push(...[card.accelerationPhase3, card.accelerationPhase2]);
                return accelerationsArray;
            }, []);


            _maxCardAcceleration = {
                [GAME_CARD_TYPE.SKILL]: Math.max(...skillsAccelerations),
                [GAME_CARD_TYPE.SERVICE]: Math.max(...servicesAccelerations)
            }
        }

        return _maxCardAcceleration[cardType];
    },

    getPower(card, phase = store.getState().game.currentPhase) {
        const accToUse = phase === GAME_PHASES.PHASE_3 ? card.accelerationPhase3 : card.accelerationPhase2;
        let power = 1;

        if (card.__type === GAME_CARD_TYPE.SERVICE) {
            if (accToUse > 19) power = 5;
            else if (accToUse > 9) power = 4;
            else if (accToUse > 6) power = 3;
            else if (accToUse > 2) power = 2;
        } else {
            if (accToUse > 9) power = 5;
            else if (accToUse > 6) power = 4;
            else if (accToUse > 4) power = 3;
            else if (accToUse > 2) power = 2;
        }

        return power;
    },

    getCardById(cards, idToFind) {
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].id === idToFind)
                return cards[i];
        }

        return null;
    },

    getQuestionsForStep(cards, step) {
        return cards.filter(c => c.step === step);
    },

    getPitchForPhase(cards, phase) {
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].phase === phase) return cards[i];
        }

        return false;
    },

    isAssignedQuestionAnswerCorrect(assignedQuestion) {
        return this.isAnswerCorrect(assignedQuestion.card.answers, assignedQuestion.answer || []);
    },

    isAnswerCorrect(answers, selectedOptions) {
        let isCorrect = selectedOptions.length > 0;

        if (isCorrect) {
            for (let i = 0; i < answers.length; i++) {
                if ((answers[i].isCorrect && !selectedOptions.includes(answers[i].order))
                    || (!answers[i].isCorrect && selectedOptions.includes(answers[i].order))) {
                    isCorrect = false;
                    break;
                }
            }
        }

        return isCorrect;
    },

    connectCardsByIds(cards) {
        const logCardNotFoundError = (type, id) => {
            console.debug(`[CARD NOT FOUND] ${type}: ${id}`)
        };

        const servicesAndSkills = [...cards.skills, ...cards.services];
        const valuePropositions = cards.valuePropositions;

        cards.services.forEach(service => {
            if (Utils.isset(service.requirements)) {
                service.requirements.forEach(req => {
                    if (Utils.isset(req.card)) {
                        const card = req.card !== '*' ? this.getCardById(servicesAndSkills, req.card) : req.card;
                        if (!card) logCardNotFoundError('Skill (for service)', req.card);

                        req.card = card;
                    }
                });
            }

            if (service.relatedValuePropositions) {
                service.relatedValuePropositions = service.relatedValuePropositions.map(relatedVp => {
                    const card = relatedVp !== '*' ? this.getCardById(valuePropositions, relatedVp) : relatedVp;
                    if (!card) logCardNotFoundError('Value proposition (for service)', relatedVp);

                    return card;
                });
            }
        });

        cards.unpredictabilities.forEach(unp => {
            if (Utils.isset(unp.relatedCard)) {
                const card = this.getCardById(servicesAndSkills, unp.relatedCard);
                if (!card) logCardNotFoundError('Service (for unpredictability)', unp.relatedCard);

                unp.relatedCard = card;
            }
        });

        cards.startups.forEach(stp => {
            stp.defaultFounders = stp.defaultFounders.map(founder => {
                const card = this.getCardById(cards.skills, founder);
                if (!card) logCardNotFoundError('Skill (for founder)', founder);

                return card;
            });
        });

        cards.questions.forEach(question => {
            if (question.relatedStartup) {
                const card = this.getCardById(cards.startups, question.relatedStartup);
                if (!card) logCardNotFoundError('Startup (for question)', question.relatedStartup);

                question.relatedStartup = card;
            }
        });


        return cards;
    },

    getGamePhaseForStep(step) {
        if (step <= GAME_STEPS.PITCH_1) return GAME_PHASES.PHASE_1;
        if (step <= GAME_STEPS.PITCH_2) return GAME_PHASES.PHASE_2;
        return GAME_PHASES.PHASE_3;
    },

    getPitchStepForPhase(phase) {
        if (phase === GAME_PHASES.PHASE_1) return GAME_STEPS.PITCH_1;
        if (phase === GAME_PHASES.PHASE_2) return GAME_STEPS.PITCH_2;
        if (phase === GAME_PHASES.PHASE_3) return GAME_STEPS.PITCH_3;
        return 0;
    },

    hiredCardsToArrayOfGameCards(hiredCards) {
        return Object.values(hiredCards).reduce((previousValue, currentValue) => {
            return [...previousValue, ...currentValue.map(hiredCard => hiredCard.card)];
        }, []);
    },

    hiredCardsToArray(hiredCards) {
        return Object.values(hiredCards).reduce((previousValue, currentValue) => {
            return [...previousValue, ...currentValue];
        }, []);
    },

    checkIfHasRequirementsForService(hiredSkills, hiredServices, serviceToCheck) {
        if (!serviceToCheck.requirements) return true;

        for (let i = 0; i < serviceToCheck.requirements.length; i++) {
            const req = serviceToCheck.requirements[i];
            let found = 0;
            let success = false;

            if (Utils.isset(req.area)) {
                // requirement per area
                for (let j = 0; j < hiredSkills.length; j++) {
                    if (hiredSkills[j].area === req.area) {
                        found++;

                        if (found === req.amount) {
                            success = true;
                            break;
                        }
                    }
                }
            } else if (Utils.isset(req.card)) {
                const hiredCards = req.type === REQUIREMENT_AREA.SKILL ? hiredSkills : hiredServices;

                if (req.card !== '*') {
                    // requires specific card
                    for (let j = 0; j < hiredCards.length; j++) {
                        if (hiredSkills[j].id === req.card.id) {
                            found++;

                            if (found === req.amount) {
                                success = true;
                                break;
                            }
                        }
                    }
                } else {
                    // requires a certain amount of cards
                    success = hiredCards.length >= req.amount;
                }
            }

            if (!success) return false;
        }

        return true;
    },

    getMostUsedValuePropositionsForStartup(startup) {
        const vps = store.getState().cards.valuePropositions;
        const config = themes_vps_per_startup.data;
        const res = [];

        if (config[startup.id]) {
            for (let i = 0; i < config[startup.id].length && res.length < 2; i++) {
                const vpId = config[startup.id][i];
                const vpCard = CardUtil.getCardById(vps, vpId);

                if (vpCard) {
                    res.push(vpCard);
                } else {
                    console.info(
                        '[CardUtil.getMostUsedValuePropositionForStartup] Value Proposition not found for startup',
                        {startupId: startup.id, vpId: vpId}
                    );
                }
            }
        } else {
            console.info(
                '[CardUtil.getMostUsedValuePropositionForStartup] Startup not found in json',
                {startupId: startup.id}
            );
        }

        while (res.length < 2) {
            const randomCard = Utils.randomFromArray(vps);

            if (!res.includes(randomCard))
                res.push(randomCard)
        }

        console.log(res);
        return res;
    },

    replaceLineBreaksWithBr(text) {
        return text.replaceAll('\n', '<br/>');
    }
};

export default CardUtil;