import {API_ENDPOINTS} from "config/API_CONSTANTS";
import ApiService from "../common/api/ApiService";
import store from "redux/store";
import ApiDataParserService from "../common/api/ApiDataParserService";
import TeamReduxService from "../redux/TeamReduxService";
import {batch} from "react-redux";
import SessionReduxService from "../redux/SessionReduxService";
import LoggerService from "../common/LoggerService";

const TeamService = {
    async joinTeam(team) {
        return ApiService.post(API_ENDPOINTS.USERS.CHANGE_TEAM, {
            team
        }).then(response => {
            if (response.status === true) {
                SessionReduxService.updateToken(response.data.token);
                SessionReduxService.updateShowBoardForTeam(team);
                return true;
            }
            return false;
        }).catch(error => {
            console.error(error);
            LoggerService.error(error, {action: 'join team - player AuthService', team});
            return false;
        });
    },

    async fetchTeamData(team) {
        const params = {};
        if (!store.getState().session.isGameMaster) params.team = team;

        return ApiService.post(API_ENDPOINTS.TEAMS.FETCH_TEAM_DATA, params).then(response => {
            if (response.status === true) {
                const parsedResponse = ApiDataParserService.parseGameData(response.data);

                batch(() => {
                    if (parsedResponse.team1) TeamReduxService.updateAllTeamData(1, parsedResponse.team1);
                    if (parsedResponse.team2) TeamReduxService.updateAllTeamData(2, parsedResponse.team2);
                    if (parsedResponse.team3) TeamReduxService.updateAllTeamData(3, parsedResponse.team3);
                    if (parsedResponse.team4) TeamReduxService.updateAllTeamData(4, parsedResponse.team4);
                });

                return true;
            }

            return false;
        }).catch(error => {
            console.error('Error fetching team data')
            console.error(error);
            LoggerService.error(error, {action: 'fetch team data - TeamService', team});

            return false;
        });
    },

    leaveTeam() {
        return ApiService.post(API_ENDPOINTS.USERS.CHANGE_TEAM, {team: null})
            .then(response => {
                if (response.status === true) {
                    SessionReduxService.updateToken(response.data.token);
                    SessionReduxService.updateShowBoardForTeam(null);
                    SessionReduxService.updateHasLoadedTeamData(0);

                    return true;
                }
                return false;
            }).catch(error => {
                console.error(error);
                LoggerService.error(error, {action: 'leaveTeam - TeamService'});
                return false;
            });
    },

    updateProfile(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.UPDATE_PROFILE, params).then(response => {
            return response.status === true;
        }).catch(error => {
            console.error(error);
            LoggerService.error(error, {action: 'updateProfile - TeamService', params});
            return false;
        });
    },

    updateQuestionAnswer(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.UPDATE_QUESTION_ANSWER, params).then(response => {
            return response.status === true;
        }).catch(error => {
            console.error(error);
            LoggerService.error(error, {action: 'updateQuestionAnswer - TeamService', params});
            return false;
        });
    },

    signAccounting(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.SIGN_ACCOUNTING, params).then(response => {
            return response.status === true;
        }).catch(error => {
            console.error(error);
            LoggerService.error(error, {action: 'signAccounting - TeamService', params});
            return false;
        });
    }
};

export default TeamService;